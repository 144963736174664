import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { PlusIcon, DownloadIcon } from '@heroicons/react/solid'
import { CSVLink } from 'react-csv'

import DateScroll from './DateScroll'
import TabsFilter from './TabsFilter'

import SettingsDropdown from './SettingsDropdown'

import { numberWithCommas } from '../../../utils/utils'
import { downloadLoads } from '../../../services/httpService'

const SearchSVG = () => (
	<svg
		className="w-4 h-4 text-gray-500 dark:text-gray-400"
		aria-hidden="true"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 20 20"
	>
		<path
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
		/>
	</svg>
)

function LoadFilters({
	handleSearchChange,
	filters,
	setFilters,
	incrementWeek,
	decrementWeek,
	activeTab,
	setActiveTab,
	tabs,
	setOpen,
	handleEndDate,
	handleStartDate,
	totalSum,
	onDispatcherChange,
	onDriverChange,
	onStatusChange,
}) {
	const [downloadable, setDownloadable] = useState([])
	const csvLink = useRef()

	const downloadHandler = async () => {
		try {
			// Create a copy of the filters to modify
			const downloadFilters = { ...filters }

			// Set status as an array of individual statuses
			downloadFilters.status = [
				'DELIVERED',
				'INVOICED',
				'CANCELLED',
				'REJECTED',
				'NOT PAID',
				'PAID',
			]

			// Add a flag to indicate we want all loads (no pagination)
			downloadFilters.download = true

			const loads = await downloadLoads(downloadFilters)

			if (!loads || loads.length === 0) {
				alert('No data available to download.')
				return
			}

			setDownloadable(loads)

			setTimeout(() => {
				if (csvLink.current) {
					csvLink.current.link.click()
				}
			}, 100)
		} catch (error) {
			console.error('Error downloading loads:', error)
			alert('Failed to download loads. Please try again.')
		}
	}

	const headers = [
		{ label: 'Load#', key: 'loadId' },
		{ label: 'Payout', key: 'price' },
		{ label: 'Dispatcher', key: 'dispatcher.name' },
		{ label: 'Driver', key: 'drivers.firstName' },
		{ label: 'Origin', key: 'origin' },
		{ label: 'Destination', key: 'destination' },
		{ label: 'Status', key: 'status' },
		{ label: 'Date', key: 'dropoffDate' },
	]

	return (
		<div className="flex flex-col w-full">
			<div className="items-start mt-6">
				<button
					onClick={() => setOpen(true)}
					type="button"
					className="relative inline-flex items-center px-1.5 py-1.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600"
				>
					<PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true" />
					<span>Add New Load</span>
				</button>
			</div>

			<div className="mb-6 mt-4 justify-start flex items-center w-full">
				<div className="flex-grow flex items-center space-x-2">
					<div className="relative" style={{ minWidth: '240px' }}>
						<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
							<SearchSVG />
						</div>
						<input
							type="search"
							id="default-search"
							className="block w-full p-2 pl-8 text-sm text-gray-800 border border-gray-300 rounded-md bg-gray-50"
							placeholder="Search load id..."
							onChange={handleSearchChange}
						/>
					</div>

					<SettingsDropdown
						filters={filters}
						onDispatcherChange={onDispatcherChange}
						onDriverChange={onDriverChange}
						onStatusChange={onStatusChange}
						activeTab={activeTab}
					/>

					<TabsFilter
						tabs={tabs}
						activeTab={activeTab}
						onTabChange={setActiveTab}
						setFilters={setFilters}
					/>
				</div>

				<div className="flex items-center space-x-4">
					<div className="flex items-center space-x-2">
						<span className="text-md font-normal">{`Total: $${
							numberWithCommas(totalSum) || 0
						}`}</span>
						<DownloadIcon
							className="text-gray-600 hover:text-gray-900 h-6 w-6 cursor-pointer"
							aria-hidden="true"
							onClick={downloadHandler}
							title="Download loads"
						/>
						<CSVLink
							data={downloadable}
							asyncOnClick={true}
							filename={'loads.csv'}
							headers={headers}
							ref={csvLink}
							target="_blank"
							className="hidden"
						/>
					</div>

					<DateScroll
						filters={filters}
						incrementWeek={incrementWeek}
						decrementWeek={decrementWeek}
						handleEndDate={handleEndDate}
						handleStartDate={handleStartDate}
					/>
				</div>
			</div>
		</div>
	)
}

LoadFilters.propTypes = {
	handleSearchChange: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired,
	next: PropTypes.func,
	previous: PropTypes.func,
	incrementWeek: PropTypes.func.isRequired,
	decrementWeek: PropTypes.func.isRequired,
	activeTab: PropTypes.string.isRequired,
	setActiveTab: PropTypes.func.isRequired,
	tabs: PropTypes.array.isRequired,
}

export default LoadFilters
